/* generated */ $argv: (production: false, ftp: false);
////
/// @group style.scss
////

// сбор всех основных стилей при отключеном JavaScript
// -------------------------------------------------

@import "_settings/all";

// css
@import "_system/noscript-msg";
